<template>
  <div class="content">
    <PageHeader :title="$tc('import-contacts-component.header', 2)" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-body block-el">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-2">
              <button class="btn btn-secondary" v-modal="{ target: 'create-custom-field-modal' }">
                Criar campo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body block-el p-0">
              <form @submit.prevent="importContact" class="form-horizontal">
                <div class="modal-body">
                  <div class="form-body">
                    <div class="import-config" v-if="doImport">
                      <div class="row">
                        <div class="col-sm-12">
                          <div
                            class="alert alert-info alert-dismissible fade show"
                          >
                            {{$t('import-contacts-component.import')}}:
                            <span class="badge badge-light">{{
                              importObject.rows
                            }}</span>
                          </div>
                          <div
                            class="custom-control custom-checkbox form-check"
                          >
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="skip_first"
                              v-model="importObject.skip_first"
                            />
                            <label class="custom-control-label" for="skip_first"
                              >{{$t('contact-list-component.import.line')}}</label
                            >
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <hr />
                        </div>
                      </div>
                      <div class="card row card-import-col">
                        <div class="row card-import-col-header">
                          <div class="col-4 text-right hide-mobile">
                            <h4>{{$t('contact-list-component.import.column')}}</h4>
                          </div>
                          <div class="col-6 hide-mobile">
                            <h4>{{$t('generic-str.type')}}</h4>
                          </div>
                        </div>
                        <div class="card-import-body">
                          <div
                            class="row"
                            v-for="(column, i) in importObject.columns"
                            :key="column"
                          >
                            <div
                              class="col-sm-4 colum-margin text-right text-left-m"
                            >
                              <span>{{$t('contact-list-component.import.column')}} {{ i }}</span>
                            </div>
                            <div class="col-sm-6 colum-margin">
                              <select
                                class="form-control"
                                v-model="importObject.columns[i]"
                              >
                                <option
                                  v-for="type in columnTypes"
                                  v-bind:key="type.name"
                                  v-bind:value="type.value"
                                  v-text="type.name"
                                ></option>
                                <option
                                  v-for="(type, index) in customFields"
                                  :key="index"
                                  :value="type.id"
                                  v-text="type.name"
                                ></option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="col-sm-12">
                        <hr />
                      </div>
                      <div class="row">
                        <span class="col-md-4 text-right text-left-m">
                          <h5>{{$t('contact-list-component.import.associate')}}</h5>
                        </span>
                        <div class="col-md-6">
                          <select-input
                            label="name"
                            :reduce="(group) => group.id"
                            v-model="importObject.group_id"
                            :options="groups"
                            :clearable="false"
                          />
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <span class="col-md-4 text-right text-left-m">
                          <h5>{{$t('contact-list-component.import.duplicated')}}</h5>
                        </span>
                        <div class="col-md-6">
                          <select
                            class="form-control"
                            v-model="importObject.on_conflict"
                          >
                            <option value="skip">{{$t('contact-list-component.import.skip')}}</option>
                            <option value="overwrite">{{$t('contact-list-component.import.replace')}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row" v-if="!doImport">
                      <label
                        class="control-label text-right col-md-4 text-left-m"
                        >{{$t('generic-str.import')}}:</label
                      >
                      <div class="col-md-6">
                        <div
                          class="custom-control custom-radio custom-control-inline"
                        >
                          <input
                            type="radio"
                            v-model="type"
                            value="file"
                            checked
                            id="typeFile"
                            name="file_type"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="typeFile"
                            >{{$t('generic-str.archive')}}</label
                          >
                        </div>
                        <div
                          class="custom-control custom-radio custom-control-inline"
                        >
                          <input
                            type="radio"
                            v-model="type"
                            value="text"
                            id="typeText"
                            name="file_type"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="typeText"
                            >{{$tc('options-div.types.text', 1)}}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group row"
                      v-if="type == 'file' && !doImport"
                    >
                      <label
                        class="control-label text-right col-md-4 text-left-m"
                        >{{$tc('sms.send-msg.tabs.contact.title', 2)}}:</label
                      >
                      <div class="col-md-6">
                        <div class="custom-file">
                          <div class="row no-margin">
                            <div class="col-lg-7 col-md-7">
                              <input
                                type="file"
                                accept=".csv"
                                @change="processFile($event)"
                                class="custom-file-input"
                                required
                                id="validatedCustomIconLabel"
                                role="button"
                              />
                              <label
                                class="custom-file-label btn-icon"
                                for="validatedCustomIconLabel"
                                >{{ file_name }}</label
                              >
                            </div>
                            <div class="col-lg-5 col-md-5 no-padding-m">
                              <a
                                href="resources/contacts_import.csv"
                                target="_blank"
                                class="btn-export btn btn-success btn-sm"
                                >{{$t('generic-str.file-example')}}</a
                              >
                            </div>
                          </div>
                          <label class="file-tip"
                            >{{$t('generic-str.file-csv')}}</label
                          >
                          <div class="invalid-feedback"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group row"
                      v-if="type == 'text' && !doImport"
                    >
                      <label
                        class="control-label text-right col-md-4 text-left-m"
                        >{{$tc('sms.send-msg.tabs.contact.title', 2)}}:</label
                      >
                      <div class="col-md-6">
                        <div class="custom-file">
                          <textarea
                            class="form-control"
                            v-model="form.content"
                            rows="4"
                            required
                          ></textarea>
                          <label class="file-tip"
                            >{{$t('contact-list-component.import.csv')}}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    v-if="!doImport"
                    :class="{
                      'qt-loader qt-loader-mini qt-loader-right': isSending,
                    }"
                    :disabled="isSending"
                    type="submit"
                    class="btn btn-primary"
                  >
                    {{$t('generic-str.import')}}
                  </button>
                  <a
                    type="button"
                    class="btn btn-secondary"
                    v-if="doImport"
                    @click="cancelImport"
                    >{{$t('generic-str.return')}}</a
                  >
                  <a
                    v-if="doImport"
                    :class="{
                      'qt-loader qt-loader-mini qt-loader-right': isSending,
                    }"
                    :disabled="isSending"
                    type="button"
                    @click="importContactFinish"
                    class="btn btn-success"
                    >{{$t('generic-str.status.lbl-finish')}}</a
                  >
                </div>
              </form>
            </div>
          </div>

          <div class="card" v-if="files.length > 0 && !doImport">
            <div class="card-body block-el p-0">
              <div class="modal-body">
                <div class="form-body">
                  <div class="row card-import">
                    <h3 class="text-secondary">{{$t('generic-str.imported')}}</h3>
                  </div>
                  <div class="row table-responsive card-import card-custom">
                    <table class="table table-borderless table-import">
                      <thead class="bg-light">
                        <tr>
                          <th>{{$tc('generic-str.name', 1)}}</th>
                          <th>{{$t('generic-str.date')}}</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody v-for="imported in files" :key="imported.id">
                        <tr class="tr-height">
                          <td>{{ imported.name }}</td>
                          <td>{{ imported.created_at | formatDate }}</td>
                          <td>
                            <span
                              class="badge badge-warning"
                              v-if="imported.status == 'queued'"
                              >{{$t('generic-str.status.lbl-queue')}}</span
                            >
                            <span
                              class="badge badge-info"
                              v-if="imported.status == 'new'"
                              >{{$t('generic-str.status.lbl-new')}}</span
                            >
                            <span
                              class="badge badge-success"
                              v-if="imported.status == 'done'"
                              >{{$tc('generic-str.status.lbl-finished', 2)}}</span
                            >
                            <span
                              class="badge badge-danger"
                              v-if="imported.status == 'failed'"
                              >{{$t('generic-str.status.lbl-failure')}}</span
                            >
                          </td>
                          <td align="right">
                            <button
                              type="button"
                              @click="configImport(imported)"
                              v-if="imported.status == 'new'"
                              class="btn btn-info btn-sm"
                            >
                              {{$t('generic-str.status.lbl-finish')}}
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="!loaded" class="qt-block-ui" />
                    <br />
                    <pagination :lastPage="pages" @change="fetch" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <create-product-modal id="create-product-modal" @sent="fetch" /> -->
    <create-custom-field-modal id="create-custom-field-modal" @created="fetchCustomFields()" />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from '@/plugins/axios';
import PageHeader from '@/components/PageHeader.vue';
import SelectInput from '@/components/SelectInput.vue';
import ContactService from '@/services/contact.service';
import Pagination from '@/components/Pagination.vue';
import CreateCustomFieldModal from '@/components/contacts/CreateCustomFieldModal.vue';

export default {
  name: 'ImportContact',
  components: {
    PageHeader,
    SelectInput,
    Pagination,
    CreateCustomFieldModal,
  },
  data() {
    return {
      columnTypes: [
        { value: 'first_name', name: 'Primeiro nome' },
        { value: 'last_name', name: this.$t('generic-str.lbl-last-name') },
        { value: 'mobile_number', name: this.$t('generic-str.lbl-cellphone') },
        { value: 'email', name: 'Email' },
        { value: '', name: this.$t('contact-list-component.import.no-import') },
      ],
      groups: [],
      customFields: [],
      type: 'file',
      fileImport: {},
      loaded: true,
      files: [],
      file_name: this.$t('generic-str.select-archive'),
      doImport: false,
      importObject: {
        on_conflict: 'skip',
        skipe_first: false,
        group_id: null,
        columns: [],
      },
      newImport: {},
      form: {
        content: '',
        group: '',
        page: '',
      },
      pages: 1,
      isSending: false,
    };
  },
  created() {
    this.fetchCustomFields();
    this.fetchGroups();
    this.fetch(1);
  },
  methods: {
    getImporteds() {
      console.log('nothing...');
    },
    cancelImport() {
      this.doImport = false;
      this.isSending = false;
    },
    configImport(importObject) {
      this.doImport = true;
      this.isSending = false;
      this.importObject = importObject;
    },
    fetch(page) {
      this.form.page = page;
      this.loaded = false;
      ContactService.getImports(this.form).then(
        (response) => {
          this.loaded = true;
          this.files = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchCustomFields() {
      axios().get('/contacts/custom-field').then((response) => {
        this.customFields = [];

        const customFields = this.customFields;

        response.data?.forEach((field) => {
          if (field.id && field.name) {
            customFields.push({
              name: field.name,
              id: field.id,
            });
          }
        });

        console.log('CUSTOM FIELD', customFields, response);
      });
    },
    fetchGroups() {
      ContactService.getGroups().then(
        (response) => {
          this.groups = response;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    importContactFinish() {
      // console.log('importContactFinish');
      this.newImport = {
        skip_first: this.importObject.skip_first,
        group_id: this.importObject.group_id,
        columns: this.importObject.columns,
        on_conflict: this.importObject.on_conflict,
      };
      this.isSending = true;
      ContactService.importContactFinish(
        this.importObject.id,
        this.newImport,
      ).then(
        () => {
          // this.$router.go(this.$router.currentRoute);
          this.cancelImport();
          this.fetch(1);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    processFile(event) {
      this.fileImport = event.target.files[0];
      this.file_name = this.fileImport.name;
    },
    importContact() {
      this.isSending = true;
      if (this.type === 'file') {
        const formData = new FormData();
        formData.append('content', this.fileImport);
        ContactService.importContactFile(formData).then(
          (response) => {
            this.configImport(response);
            this.getImporteds();
            // this.$root.$emit('updated.contacts');
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      } else {
        ContactService.importContact(this.form).then(
          (response) => {
            this.configImport(response);
            this.getImporteds();
            // this.$root.$emit('updated.contacts');
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
.btn {
  margin-bottom: 5px;
}
.card-import {
  margin: 0px;
}
.card-import-col {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}
.card-import-col-header {
  border-bottom: solid 1px #efefef;
  height: 45px;
  margin-bottom: 10px;
  background: #f3f3f3;
  margin-top: 0px;
  padding-top: 0px;
  padding: 10px;
}
.card-import-body {
  padding: 15px;
}
.colum-margin {
  margin-bottom: 5px;
}
.file-tip {
  color: #ccc;
  margin-top: 5px;
}
.tr-height {
  height: 50px;
  border-bottom: solid 1px #f3f3f3;
}
.top-10 {
  margin-top: 10px !important;
}
.btn-export {
  padding: 8px 20px !important;
}
</style>
